<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-6 col-12">My Request</div>
    <div class="
        col-lg-6 col-12
        text-end
        d-flex
        justify-content-end
        custom-flex-cloumn-mob
      ">
    </div>
  </div>
  <ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item" role="presentation">
      <button class="nav-link custom-navbar-navlink active" id="myvoter-all-details-tab" data-bs-toggle="tab"
        data-bs-target="#myvoteralldetail" type="button" role="tab" aria-controls="vehicle-details"
        aria-selected="true"><i class="pi pi-user me-1" :style="{ fontSize: '0.7rem' }"></i>My Request
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link custom-navbar-navlink" id="teamvoter-report-tab" data-bs-toggle="tab" data-bs-target="#teamvoterreport"
        type="button" role="tab" aria-controls="customer-details" aria-selected="false" @click="redirectteamtagrequest()">
        <i class="pi pi-users me-1" :style="{ fontSize: '0.7rem' }"></i>Teams Request
      </button>
    </li>
  </ul>
  <div class="tab-content mt-2" id="myTabContent">
    <div class="tab-pane fade show active" id="myvoteralldetail" role="tabpanel"
      aria-labelledby="myvoter-all-details-tab">
      <div class="custom-ultima-datatable" style="height: calc(100vh - 185px)">
        <DataTable :value="myRequestList" :scrollable="true" columnResizeMode="fit" scrollHeight="flex" :paginator="true"
          :rows="items_per_page" :totalRecords="totalRecords" @page="changePage($event)"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" :lazy="true"
          dataKey="dt1" :loading="loading">
          <template v-if="!loading" #empty>No records found.</template>
          <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
          <Column field="votername" header="Voter Name" headerStyle="width: 24%" bodyStyle="width: 24%">
            <template #body="{ data }">
              <div class="d-flex align-items-center justify-content-between w-100">
                <div>
                  <div class="label-subheading text-capitalize">{{ data.ak5 ? data.ak5 : "N/A" }}</div>
                  <Divider type="dashed" />
                  <div class="label-subheading" :title="data.ak35 ? 'Voter Id' : ''">{{ data.ak35 ? data.ak35 : "N/A" }}
                  </div>
                </div>
                <div>
                  <button type="button" title="View Voter Details" class="btn custom-outline-view-btn ms-4"
                    @click="voterdetailModalOpen(data)">
                    <i class="pi pi-eye call-btn-color"></i>
                  </button>
                </div>
              </div>
            </template>
          </Column>
          <Column field="type" header="Request Type" headerStyle="width: 12%" bodyStyle="width: 12%">
            <template #body="{ data }">
              <div class="label-subheading">
                <span v-if="data.dt2 == 1">Tag Addition</span>
                <span v-else-if="data.dt2 == 2">Tag Removal</span>
                <span v-else>N/A</span>
              </div>
            </template>
          </Column>
          <Column field="tagname" header="Tag Name" headerStyle="width: 29%" bodyStyle="width: 29%">
            <template #body="{ data }">
              <div class="label-subheading d-flex flex-wrap" v-if="data.approved_or_rejected_tags">
                <span :class="data.dt2 == 1 ? 'TagAdditionStatus' : 'TagRemovalStatus'" v-for="(tagname, key) in data.approved_or_rejected_tags" :key="key"> {{ tagname.av5 }}</span>
              </div>
              <div class="label-subheading" v-else>N/A</div>
            </template>
          </Column>
          <Column field="requestby" header="Approver By" headerStyle="width: 20%" bodyStyle="width: 20%" >
            <template #body="{ data }">
              <div>
                <div class="label-subheading text-capitalize">
                    {{ data.dt12 ? data.dt12 : "-" }}
                </div>
                <Divider v-if="data.dt13" type="dashed" />
                <div class="label-subheading">
                  {{ format_timestamp(data.dt13) }}
                </div>
              </div>
            </template>
          </Column>
          <Column field="action" header="Status" headerStyle="width: 15%" bodyStyle="width: 15%"
            class="justify-content-center">
            <template #body="{ data }">
              <div>
                <span v-if="data.dt15 == 2" class="status-request-approved">Approved</span>
                <span v-if="data.dt15 == 3" class="status-request-rejected">Rejected</span>
                <span v-if="data.dt15 == 1" class="status-request-submitted">Submitted</span>
                <span v-if="data.dt15 == 4" class="status-request-rejected">Cancelled</span>
              </div>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <div class="tab-pane fade" id="teamvoterreport" role="tabpanel" aria-labelledby="teamvoter-report-tab">
      Teams Voters
    </div>
  </div>
  <!-- next action modal start here -->
  <div class="modal-mask" v-if="viewvoterdetailstatus">
    <div class="modal-dialog modal-lg modal-dialog-centered custom-modal-outer">
      <div class="modal-content">
        <div class="modal-header custom-custmer-header-border">
          <h5 class="modal-title" id="staticBackdropLabel">Voter Details</h5>
          <button type="button" class="btn-close" @click="voterDetailModalClose()"></button>
        </div>
        <div class="modal-body modal-body-scroll-outer">
          <div class="voter-detail-card mb-3">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12">
                <div class="customer-label-group">
                  <div class="d-flex align-items-center">
                    <div class="flex-shrink-0">
                      <img
                        v-if="(votergovdata.ak90 == null || votergovdata.ak90 == '') && (votergovdata.ak33 == null || votergovdata.ak33 == '')"
                        src="/assets/images/profile.svg" class="
                                        rounded
                                        bg-light
                                        me-auto
                                        d-block
                                      " width="90" alt="" />
                      <img v-if="votergovdata.ak90 != null && votergovdata.ak90 != ''"
                        :src="'https://storage.googleapis.com/' + this.voterstorageimgpath + '/voterphoto/' + votergovdata.ak90"
                        class="
                                        rounded
                                        bg-light
                                        me-auto
                                        d-block
                                        
                                      " width="90" alt="" />
                      <img
                        v-if="(votergovdata.ak33 != null && votergovdata.ak33 != '') && (votergovdata.ak90 == null || votergovdata.ak90 == 'NULL' || votergovdata.ak90 == '')"
                        :src="'https://storage.googleapis.com/' + this.voterstorageimgpath + '/aadharphoto/' + votergovdata.ak33"
                        class="
                                        rounded
                                        bg-light
                                        me-auto
                                        d-block
                                      " width="90" alt="" />
                    </div>
                    <div class="flex-grow-1 ms-3 text-capitalize">
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Voter Name</label>
                        <div class="from-label-value text-capitalize"
                          :title="votergovdata.ak5 ? firstCapitalize(votergovdata.ak5) : ''">{{ votergovdata.ak5 ?
                            votergovdata.ak5 : "N/A" }}</div>
                      </div>
                      <div class="customer-label-group mb-2">
                        <label class="form-label">Mobile Number</label>
                        <div class="from-label-value" v-if="this.allowshowmobilevoterfk == 0">
                          +91-{{ votergovdata.ak24 ? maskedNumber(votergovdata.ak24) : "N/A" }}
                        </div>
                        <div class="from-label-value" v-if="this.allowshowmobilevoterfk == 1">
                          +91-{{ votergovdata.ak24 ? votergovdata.ak24 : "N/A" }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-12">
                <div class="customer-label-group mb-2">
                  <label class="form-label"><span style="width: 87px; display: inline-block;">Date of
                      Birth</span><span class="mx-1 vertical-pipe-color">|</span><span>Age</span></label>
                  <div class="from-label-value">
                    <span style="width: 87px; display: inline-block;">{{ format_date(votergovdata.ak18)
                      }}</span><span class="mx-1 vertical-pipe-color">|</span><span>{{ votergovdata.ak19 ?
                      votergovdata.ak19 : "N/A" }}</span>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-12">
                <div class="customer-label-group mb-2">
                  <label class="form-label">Gender</label>
                  <div class="from-label-value text-capitalize">
                    {{
                      votergovdata.ak22
                        ? votergovdata.ak22 == 1
                          ? "Male"
                          : votergovdata.ak22 == 2
                            ? "Female"
                            : "Others"
                    : "N/A"
                    }}
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-12">
                <div class="customer-label-group mb-2">
                  <label class="form-label">Reg. Date & Time</label>
                  <div class="from-label-value text-capitalize">
                    {{ format_timestamp(votergovdata.z501) }}
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-12">
                <div class="customer-label-group mb-2">
                  <label class="form-label"><span style="width: 87px; display: inline-block;">Voter
                      Id</span><span class="mx-1 vertical-pipe-color">|</span><span>Part
                      No.</span></label>
                  <div class="from-label-value text-capitalize">
                    <span style="width: 87px; display: inline-block;">{{ votergovdata.ak35 ? votergovdata.ak35 : "N/A"
                      }}</span><span class="mx-1 vertical-pipe-color">|</span><span>{{ votergovdata.ak85 ?
                        votergovdata.ak85 : "N/A" }}</span>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-12">
                <div class="customer-label-group mb-2">
                  <label class="form-label">Vidhan Sabha</label>
                  <div class="from-label-value text-capitalize">
                    {{ votergovdata.ak61 ? votergovdata.ak61 : "N/A" }}
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-12">
                <div class="customer-label-group mb-2">
                  <label class="form-label">Lok Sabha</label>
                  <div class="from-label-value text-capitalize">
                    {{ votergovdata.ak63 ? votergovdata.ak63 : "N/A" }}
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-12" v-if="votergovdata.ak67">
                <div class="customer-label-group mb-2">
                  <label class="form-label">Profession</label>
                  <div class="from-label-value text-capitalize">
                    {{
                      votergovdata.ak67
                        ? votergovdata.ak67
                        : "N/A"
                    }}
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-12" v-if="votergovdata.ak84">
                <div class="customer-label-group mb-2">
                  <label class="form-label">Designation</label>
                  <div class="from-label-value text-capitalize">
                    {{
                      votergovdata.ak84
                        ? votergovdata.ak84
                        : "N/A"
                    }}
                  </div>
                </div>
              </div>
              <div class="col-lg-8 col-md-8 col-12">
                <div class="customer-label-group mb-2">
                  <label class="form-label">Address</label>
                  <div class="from-label-value text-capitalize">
                    {{
                      votergovdata.ak51
                        ? votergovdata.ak51
                        : "N/A"
                    }}
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-12">
                <div class="customer-label-group mb-2">
                  <label class="form-label">Area</label>
                  <div class="from-label-value text-capitalize">
                    <span v-if="votergovdata.ak44 != null && votergovdata.ak44 != ''" :title="votergovdata.ak44 ? firstCapitalize(votergovdata.ak44) : ''">{{ votergovdata.ak44 }}</span>
                    <span v-else-if="votergovdata.ak53 != '' && votergovdata.ak53 != ''" :title="votergovdata.ak53 ? firstCapitalize(votergovdata.ak53) : 'N/A'">{{ votergovdata.ak53 }}</span>
                    <span v-else>N/A</span>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-12">
                <div class="customer-label-group mb-2">
                  <label class="form-label">Pincode</label>
                  <div class="from-label-value text-capitalize">
                    <span v-if="votergovdata.ak58 != null && votergovdata.ak58 != ''">{{ votergovdata.ak58 }}</span>
                    <span v-else-if="votergovdata.ak97 != null && votergovdata.ak97 != ''">{{ votergovdata.ak97}}</span>
                    <span v-else>N/A</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ApiService from "../../service/ApiService";
import moment from 'moment';
export default {
  data() {
    return {
      myRequestList: [],
      totalRecords: 0,
      page_no: 0,
      items_per_page: 30,
      loading: false,
      viewvoterdetailstatus: false,
      votergovdata: '',
      user_info: '',
      allowshowmobilevoterfk: '',
      voterstorageimgpath: '',
      client_info: '',
    };
  },

  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },

  mounted() {
    this.getVoterTagRequestByAction();
    this.client_info = JSON.parse(localStorage.client_info);
    this.voterstorageimgpath = this.client_info.maa24;
    this.user_info = JSON.parse(localStorage.user);
    this.allowshowmobilevoterfk = this.user_info.ak105;
  },
  methods: {
    getVoterTagRequestByAction(e) {
      this.loading = true;
      this.ApiService.getVoterTagRequestByAction(e).then((data) => {
        if (data.status == 200) {
          this.myRequestList = data.data;
          this.totalRecords = data.count;
          this.loading = false;
        } else {
          this.myRequestList = data.data;
          this.totalRecords = data.count;
          this.loading = false;
        }
      });
    },
    redirectteamtagrequest() {
      this.$router.push("/approvals/teamsrequest");
    },
    changePage(event) {
      this.page_no = event.page;
      this.getVoterTagRequestByAction({ page_no: this.page_no, items_per_page: this.items_per_page });
    },
    format_datetime(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY @ hh:mm A");
      } else {
        return 'N/A';
      }
    },
    format_timestamp(value) {
      if (value) {
        var localTime = new Date(value * 1000).toUTCString();
        return moment(localTime).format("DD/MM/YYYY @ hh:mm A");
      }
    },
    voterdetailModalOpen(e) {
      this.viewvoterdetailstatus = true;
      console.log(e);
      this.votergovdata = e;
    },

    voterDetailModalClose() {
      this.viewvoterdetailstatus = false;
    },
    firstCapitalize(e) {
      const str = e;
      const arr = str.split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);

      }
      const str2 = arr.join(" ");
      return str2;
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      } else {
        return 'N/A';
      }
    },
    splitJoin ( theText ) {
        return theText.split( ',' );
    },
  },
};
</script>
<style scoped>
.custom-request-approve-btn{
background: #E4F8F0 0% 0% no-repeat padding-box;
  border: 1px solid #BBD9CC;
  border-radius: 4px;
  padding: 7.5px;
  line-height: 10px;
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0px;
  color: #2CA270;
}
.custom-request-approve-btn .approve-btn-color{
color: #2CA270;
font-size: 13px;
}
.custom-request-reject-btn{
background: #ffcdd2 0% 0% no-repeat padding-box;
  border: 1px solid #d5b1b1;
  border-radius: 4px;
  padding: 7.5px;
  line-height: 10px;
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0px;
  color: #c63737;
}
.custom-request-reject-btn .reject-btn-color{
color: #c63737;
font-size: 13px;
}
.status-request-approved {
  background: #c8e6c9;
  border-radius: 2px;
  letter-spacing: 0.3px;
  color: #256029;
  opacity: 1;
  font-size: 12px;
  font-family: "AcuminPro-Bold";
  padding: 0.3em 0.2rem 0.2em 0.2rem;
  text-transform: capitalize;
  border: 1px solid #4daa53;
  line-height: 12px;
}

.status-request-rejected {
  background: #ffcdd2;
  border-radius: 2px;
  letter-spacing: 0.3px;
  color: #c63737;
  opacity: 1;
  font-size: 12px;
  font-family: "AcuminPro-Bold";
  padding: 0.3em 0.2rem 0.2em 0.2rem;
  text-transform: capitalize;
  border: 1px solid #d7a5a5;
  line-height: 12px;
}
.status-request-submitted {
  background: #fde3c8;
  border-radius: 2px;
  letter-spacing: 0.3px;
  color: #805b36;
  opacity: 1;
  font-size: 12px;
  font-family: "AcuminPro-Bold";
  padding: 0.3em 0.2rem 0.2em 0.2rem;
  text-transform: capitalize;
  border: 1px solid #bba691;
  line-height: 12px;
}

.custom-request-reject-btn {
  background: #ffcdd2 0% 0% no-repeat padding-box;
  border: 1px solid #d5b1b1;
  border-radius: 4px;
  padding: 7.5px;
  line-height: 10px;
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0px;
  color: #c63737;
}

.custom-request-reject-btn .reject-btn-color {
  color: #c63737;
  font-size: 13px;
}

.customer-label-group {
  margin-bottom: 20px;
}

.customer-label-group .form-label {
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0px;
  color: #4a5463;
  line-height: 13px;
  margin-bottom: 6px;
}

.customer-label-group .from-label-value {
  font-family: "AcuminPro-SemiBold";
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
  word-break: break-word;
}
.TagAdditionStatus {
    background: #c8e6c9;
    border-radius: 2px;
    letter-spacing: 0.3px;
    color: #256029;
    opacity: 1;
    font-size: 13px;
    font-family: "AcuminPro-Regular";
    padding: 0.3em 0.2rem 0.2em 0.2rem;
    text-transform: capitalize;
    border: 1px solid #4daa53;
    line-height: 15px;
    margin: 2px;
}
.TagRemovalStatus {
  background: #ffcdd2;
  border-radius: 2px;
  letter-spacing: 0.3px;
  color: #c63737;
  opacity: 1;
  font-size: 13px;
  font-family: "AcuminPro-Regular";
  padding: 0.3em 0.2rem 0.2em 0.2rem;
  text-transform: capitalize;
  border: 1px solid #d7a5a5;
  line-height: 15px;
  margin: 2px;
}
</style>